<template>
  <PageCover :active-path="'regulament'"
             image="/img/cover-rezervari.webp"
             page-title="Regulament intern">
  </PageCover>
  <div class="mt-5">
    <section class="container text-start">
      <div>
        <p>
          Acest regulament a fost implementat strict în interesul şi pentru siguranţa
          vizitatorilor noştri. Persoanele care le apreciaza ca fiind restrictive sau nu
          işi exprima acordul cu privire la respectarea lor, au opţiunea de a nu intra in incintā.
          <br><br>
          Odatā intrat în perimetrul Bunker Laser Tag Arena, clienţii consimt regulile
          şi se obliga la respectarea lor.
          <br><br>
          Bunker Laser Tag Arena este o proprietate privatā şi este obligatorie luarea la
          cunoştinţa a prezentului regulament.
        </p>
        <ol claass="pt-5">
          <li>
            Pentru participare, varsta minima este de 7 ani.
            Minorii sub 12 ani trebuie sa fie insoţiţi de aun adult.
          </li>
          <li>
            Activitaţile susţinute de noi nu sunt recomandate persoanelor care suferā de epilepsie.
            Nu este interzisā participarea persoanelor care au nevoie de supraveghere
            speciala(dizabilitaţi fizice, afecţiuni psihice, comportament exuberant, ADHD etc.)
            sau care prin comportament pot afecta ceilalti clienţi, însā supraveghetorii sunt
            direct responsabili de acţiunile lor.
          </li>
          <li>
            Echiparea, dezechiparea, instructajul sunt facute doar de membrii staff-ului nostru.
          </li>
          <li>
            În cazul în care nu se respecta regulile prezentate, veţi fi oprit şi vi se vor
            repeta regulile. În cazul abaterilor repetate, veţi fi oprit si se va preda
            echipamentul catre personalul Bunker Laser Tag Arena.
          </li>
          <li>
            Este strict interzisā distrugerea/deteriorarea
            bunurilor/echipamentelor/locurilor amenajate/dotărilor din incintă.
          </li>
          <li>
            Orice acțiune intenționată de distrugere a bunurilor ce aparțin
            Bunker Laser Tag Arena va fi imputată celui responsabil, valoarea
            acestora fiind stabilită conform situației din contabilitatea societății.
          </li>
          <li>
            În conformitate cu specificul jocului, arena a fost special amenajată astfel încât să
            imite teatrul unor operații militare, ceea ce presupune asumarea riscului și
            răspunderii individuale a jucătorului pentru orice accidentare.
          </li>
          <li>
            Este interzis accesul în incinta Bunker Laser Tag Arena cu produse alimentare,
            sau orice tip de băutură, sau a armelor de orice gen.
          </li>
          <li>
            Este interzis comportamentul agresiv, fizic sau verbal la adresa
            altor participanți sau a personalului nostru.
          </li>
          <li>
            Sunt interzise vânzarea/cumpărarea/consumul/posesia de droguri sau
            a altor substanțe interzise prin lege în interiorul Bunker Laser Tag Arena.
          </li>
          <li>
            Este interzisă intrarea persoanelor aflate în stare de ebrietate/sub influență
            drogurilor sau cu comportament violent.
          </li>
          <li>
            Nu folosiți atracțiile Bunker Laser Tag Arena dacă ați consumat
            alcool sau suferiți de diverse afecțiuni medicale ce pot include dar
            fără a se limita la: leșinuri, aritmii cardiace, boli cardiace, tensiune,
            fobii, pierderea cunoștinței etc.
          </li>
          <li>
            Bunker Laser Tag Arena nu își asumă răspunderea pentru niciun incident
            provocat în incinta, care rezultă din existența unei situații medicale
            precare, nerespectarea instrucțiunilor personalului nostru.
          </li>
          <li>
            Este interzisă atingerea firelor/panourilor electrice/butoanelor de incendiu/panică.
          </li>
          <li>
            Aruncarea gunoaielor se face în recipientele aferente din incinta.
          </li>
          <li>
            Fumatul este interzis, dar se permite utilizarea dispozitivelor de tip vaping
            sau tutun încălzit.
          </li>
          <li>
            În timpul activităților, se va recomandă că participanții să nu aibă asupra lor
            telefoane/ceasuri/bijuterii ,aceștia fiind răspunzători pentru
            spargerea/ruperea/pierderea lor
          </li>
          <li>
            Prin citirea acestui regulament, clienții își dau acordul la posibilitatea includerii
            lor în fotografii/videouri pentru ilustrare și suport comercial. Prin acceptarea acestor
            condiții, Bunker Laser Tag Arena este protejat de orice revendicare cu privire
            la dreptul de imagine.
          </li>
          <li>
            Persoanele care intră în incinta Bunker Laser Tag Arena au nevoie de acordul
            staff-ului pentru a face fotografii/videouri.
          </li>
          <li>
            Este strict interzisă introducerea/folosirea/abandonarea în incinta
            Bunker Laser Tag Arena a oricărui obiect ascuțit sau casant ce poate provoca răni.
          </li>
          <li>
            Bunker Laser Tag Arena nu eate răspunzător de pierderea/furtul obiectelor
            lăsate nesupravegheate în incinta.
          </li>
          <li>
            Bunker Laser Tag Arena nu este responsabil pentru eventualele accidente survenite
            și prejudicii cauzate terțelor persoane că urmare a nerespectării de către participanți
            a indicațiilor staff-ului din administrația arenei și a prezentului regulament.
          </li>
        </ol>
        <p class="pt-5">
          Bunker Laser Tag Arena își rezervă următoarele drepturi:
        </p>
        <ol>
          <li>
            Să excludă din incinta persoanele care încalcă regulile care ar afecta desfășurarea
            normală a activității. În acest caz, plata accesului este nerambursabilă.
          </li>
          <li>
            Să nu permită intrarea în incinta a persoanelor aflate
            sub influență alcoolului sau substanțelor halucinogene
          </li>
          <li>
            Să limiteze accesul în incinta în timpul unor evenimente private/speciale.
          </li>
        </ol>
        <p>
          Orice reclamație ulterioară datorată încălcării regulamentului de mai sus nu va
          fi luată în considerare.
          <br>
          Va mulțumim, Echipa Bunker.
        </p>
      </div>
    </section>

  </div>
  <Footer></Footer>
</template>

<script>
import PageCover from '../components/PageCover.vue';
import Footer from '../components/Footer.vue';

export default {
  name: 'Regulament',
  components: {
    PageCover,
    Footer,
  },
};
</script>

<style scoped>

</style>
